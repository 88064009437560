import Api from '@/api';
import { acceptanceDetail, acceptanceList } from './selectors';

class ACTSVR extends Api {
  acceptanceList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/actsvr/acceptance/list.json', {
      method: 'POST',
      data: { page, size, filter },
    }).then(acceptanceList);
  }
  acceptanceDetail({ id }) {
    if (!id) return Promise.resolve(acceptanceDetail());
    return this.rest('/actsvr/acceptance/item.json',{
      method: 'POST',
      data: {
        id,
      },
    })
    .then(acceptanceDetail);
  }
}

export default new ACTSVR();
