<template>
  <VCard>
    <VCardTitle>Согласие на обработку персональных данных</VCardTitle>
    <VCardText>
      <p>Согласие на обработку персональных данных Пользователя единого сервера авторизации, в отношении которых Пользователь предоставляет их персональные данные.</p>
      <ol>
        <li class="mb-3">Пользователь, указывая на Едином сервере авторизации (https://esa.evraz.com) или сообщая свои персональные данные, а также персональные данные третьих лиц (с их согласия), или передавая их Оператору иным способом, принимает и дает настоящее Согласие на обработку персональных данных (далее – Согласие).</li>
        <li>При предоставлении Оператору персональных данных третьих лиц, Пользователь обязуется иметь согласие третьих лиц на обработку и передачу их персональных данных Оператору в соответствии и в порядке, установленном Федеральным законом №152-ФЗ «О персональных данных».</li>
      </ol>
    </VCardText>
  </VCard>
</template>

<script>
export default {
  name: 'AgreeCard'
}
</script>

<style scoped>

</style>
