import { set, get, toNumber, toString, pickBy, reduce, omit } from 'lodash-es';

export default [
  {
    path: '/pkosmr/',
    name: 'pkosmr/CounterpartyListView',
    meta: { title: 'Список заявок' },
    props: route => ({
      filter: {
        id: toString(get(route, 'query.id', '')),
        inn: toString(get(route, 'query.inn', '')),
        companyName: toString(get(route, 'query.companyName', '')),
        status: toString(get(route, 'query.status', '')),
      },
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/pkosmr/CounterpartyListView/CounterpartyListView'),
  },
  {
    path: '/pkosmr/survey/registry/',
    name: 'pkosmr/SurveyListView',
    meta: { title: 'Результаты опроса по функционалу ПКО СМР' },
    props: route => ({
      filter: omit( get(route, 'query'), ['page', 'size']),
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/pkosmr/SurveyListView/SurveyListView'),
  },
  {
    path: '/pkosmr/survey/:id?',
    name: 'pkosmr/SurveyDetailView',
    meta: { title: 'Опрос по функционалу ПКО СМР' },
    props: route => ({
      id: toString(get(route, 'params.id')),
    }),
    component: () => import('@/views/pkosmr/SurveyDetailView/SurveyDetailView'),
  },
  {
    path: '/pkosmr/registry/',
    name: 'pkosmr/RegistryListView',
    meta: { title: 'Реестр контрагентов' },
    props: route => ({
      ...reduce(pickBy(get(route, 'query'), (value, key) => !!~key.indexOf('filter.')), (acc, value, path) => {
        if (value) set(acc, path, value);
        return acc;
      }, {}),
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/pkosmr/RegistryListView/RegistryListView'),
  },
  {
    path: '/pkosmr/form-registry/',
    name: 'pkosmr/FormRegistryListView',
    meta: { title: 'Реестр заявок' },
    props: route => ({
      filter: {
        id: toString(get(route, 'query.id', '')),
        name: toString(get(route, 'query.name', '')),
        email: toString(get(route, 'query.email', '')),
        phone: toString(get(route, 'query.phone', '')),
        status: toString(get(route, 'query.status', '')),
        inn: toString(get(route, 'query.inn', '')),
      },
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/pkosmr/FormRegistryListView/FormRegistryListView'),
  },
  {
    path: '/pkosmr/:counterparty',
    name: 'pkosmr/CounterpartyDetailView',
    meta: { title: 'Заявка' },
    props: route => ({
      counterparty: toString(get(route, 'params.counterparty')),
      question: toString(get(route, 'params.question'))
    }),
    component: () => import('@/views/pkosmr/CounterpartyDetailView/CounterpartyDetailView'),
  },
  {
    path: '/pkosmr/:counterparty/:question',
    name: 'pkosmr/CounterpartyCommentsView',
    meta: { title: 'Комментарии' },
    props: route => ({
      counterparty: toString(get(route, 'params.counterparty')),
      question: toString(get(route, 'params.question'))
    }),
    component: () => import('@/views/pkosmr/CounterpartyDetailView/CounterpartyDetailView'),
  },
  {
    path: '/pkosmr/:catchAll(.*)',
    redirect: { name: 'pkosmr/CounterpartyListView' }
  },
];
