import { get, includes } from 'lodash-es';
import api from './api';

const START = 'START';
const END = 'END';
const SET_DETAIL = 'SET_DETAIL';

export default {
  namespaced: true,
  state: {
    pending: {
      fetchItem: false,
      updateItem: false,
    },
    detail: {},
  },
  getters: {
    pending: (state) => {
      return includes(state.pending, true);
    },
    detail: (state) => {
      return get(state, 'detail');
    },
  },
  mutations: {
    [START]: (state, key) => {
      state.pending[key] = true;
    },
    [END]: (state, key) => {
      state.pending[key] = false;
    },
    [SET_DETAIL]: (state, detail) => {
      state.detail = detail;
    },
  },
  actions: {
    fetchItem: ({ commit }, { id, template }) => {
      commit(START, 'fetchItem');
      return api.item({ id, template }).then(detail => {
        commit(SET_DETAIL, detail);
        commit(END, 'fetchItem');
        return detail;
      }).catch(() => {
        commit(END, 'fetchItem');
      });
    },
    updateItem: ({ commit }, { values }) => {
      commit(START, 'updateItem');
      return api.update({ values }).then((result) => {
        commit(END, 'updateItem');
        return result;
      }).catch(() => {
        commit(END, 'updateItem');
      });
    },
  },
}
