import { isArray } from 'lodash-es';
import Api from '@/api';
import {applicationList, applicationDetail, inspectorList, commentList, timelineList} from './selectors';

class INSPECT extends Api {
  getApplicationDetail({ id }) {
    return this.rest('/inspect/application/get.json', {
      method: 'POST',
      data: { id },
    }).then(applicationDetail);
  }
  getApplicationList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/inspect/application/list.json', {
      method: 'POST',
      data: { page, size, filter },
    }).then(applicationList);
  }
  downloadApplicationList({ page = 1, size = 10, filter = {} }) {
    return this.download('/inspect/application/list-excel.json', {
      method: 'POST',
      data: { page, size, filter },
    });
  }
  createApplication() {
    return this.rest('/inspect/application/create.json');
  }
  deleteApplication({ id }) {
    return this.rest('/inspect/application/delete.json', {
      method: 'POST',
      data: { id },
    });
  }
  approveApplication({ id, action, comment }) {
    return this.rest('/inspect/application/approve-action.json', {
      method: 'POST',
      data: { id, action, comment },
    });
  }
  finishApplication({ id, comment, rating, name, fail }) {
    return this.rest('/inspect/application/finish-action.json', {
      method: 'POST',
      data: { id, comment, mark: rating, inspectorName: name, inspectorFail: fail },
    });
  }
  updateApplication({ id, fields }) {
    return this.rest('/inspect/application/edit.json', {
      method: 'POST',
      data: {
        item: {
          id,
          ...fields,
        },
      }
    });
  }
  getApplicationTimeline({ id }) {
    return this.rest('/inspect/application/timeline.json', {
      method: 'POST',
      data: { id },
    }).then(timelineList);
  }
  inviteInspector({ application,  inspectors }) {
    return this.rest('/inspect/application/invite-inspectors.json', {
      method: 'POST',
      data: {
        applicationId: application,
        inspectorIds: inspectors,
      },
    });
  }
  sendInviteInspector({ application,  inspectors }) {
    return this.rest('/inspect/application/send-invite-inspectors.json', {
      method: 'POST',
      data: {
        applicationId: application,
        inspectorIds: inspectors,
      },
    });
  }
  cancelInviteInspector({ application, comment, inspectors }) {
    return this.rest('/inspect/application/cancel-invite-inspectors.json', {
      method: 'POST',
      data: {
        applicationId: application,
        inspectorIds: inspectors,
        comment
      },
    });
  }
  decisionInspector({ application, decision, comment, inspector }) {
    return this.rest('/inspect/application/inspector-invite-decision.json', {
      method: 'POST',
      data: {
        applicationId: application,
        decision: decision ? 'AGREED': 'REJECTED',
        comment,
        slaveInspectorId: inspector,
      },
    });
  }
  delegateInspector({ application, comment, inspector }) {
    return this.rest('/inspect/application/delegate-inspector.json', {
      method: 'POST',
      data: {
        applicationId: application,
        comment,
        inspectorId: inspector,
      },
    });
  }
  getApplicationInspectorList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/inspect/application/inspector-list.json', {
      method: 'POST',
      data: { page, size, filter },
    }).then(inspectorList);
  }
  getApplicationHistory({ id }) {
    return this.rest('/inspect/application/timeline.json', {
      method: 'POST',
      data: { id },
    });
  }
  getInspectorDetail({ id }) {
    return this.rest('/inspect/inspector/get.json', {
      method: 'POST',
      data: { id },
    });
  }
  getInspectorList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/inspect/inspector/list.json', {
      method: 'POST',
      data: { page, size, filter },
    }).then(inspectorList);
  }
  getCommentList({ id }) {
    return this.rest('/inspect/comment/list.json', {
      method: 'POST',
      data: { applicationId: id },
    }).then(commentList);
  }
  createComment({ application, parent, type, code, message }) {
    return this.rest('/inspect/comment/create-many.json', {
      method: 'POST',
      data: {
        items: [{
          code,
          type,
          parentId: parent,
          applicationId: application,
          comment: message
        }],
      },
    });
  }
  editComment({ id, message }) {
    return this.rest('/inspect/comment/edit.json', {
      method: 'POST',
      data: {
        id,
        comment: message,
      },
    });
  }
  deleteComment({ id }) {
    return this.rest('/inspect/comment/delete-draft.json', {
      method: 'POST',
      data: { id },
    });
  }
  approveComment({ items }) {
    return this.rest('/inspect/comment/approve-comment.json', {
      method: 'POST',
      data: {
        items: isArray(items) ? items: [items],
      },
    });
  }
  getReportList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/inspect/appreport/list.json', {
      method: 'POST',
      data: { page, size, filter },
    });
  }
  createReport({ application, name, comment, hasTimeDeviation, hasQualityDeviation, files }) {
    return this.rest('/inspect/appreport/create.json', {
      method: 'POST',
      data: {
        applicationId: application,
        item: {
          name: {
            values: [name],
          },
          comment: {
            values: [comment],
          },
          files: {
            values: files,
          },
          hasTimeDeviation: {
            values: [hasTimeDeviation]
          },
          hasQualityDeviation: {
            values: [hasQualityDeviation]
          },
        },
      }
    });
  }
  updateReport({ id, name, comment, hasTimeDeviation, hasQualityDeviation, files }) {
    return this.rest('/inspect/appreport/edit.json', {
      method: 'POST',
      data: {
        id,
        item: {
          name: {
            values: [name],
          },
          comment: {
            values: [comment],
          },
          files: {
            values: files,
          },
          hasTimeDeviation: {
            values: [hasTimeDeviation]
          },
          hasQualityDeviation: {
            values: [hasQualityDeviation]
          },
        }
      }
    });
  }
  getChatList({ id }) {
    return this.rest('/inspect/chat/list.json', {
      method: 'POST',
      data: { applicationId: id },
    }).then(commentList);
  }
  createChatComment({ parent, application, message }) {
    return this.rest('/inspect/chat/create-many.json', {
      method: 'POST',
      data: {
        items: [{
          parentId: parent,
          applicationId: application,
          comment: message
        }],
      },
    });
  }
  editChatComment({ id, message }) {
    return this.rest('/inspect/chat/edit.json', {
      method: 'POST',
      data: {
        id,
        comment: message,
      },
    });
  }
  viewedChatList({ id }) {
    return this.rest('/inspect/chat/mark-as-viewed.json', {
      method: 'POST',
      data: {
        applicationId: id,
      },
    });
  }
  getUserByEmail({ email, code }) {
    return this.rest('/inspect/application/get-user-by-email.json', {
      method: 'POST',
      data: {
        email,
        fieldCode: code,
      },
    });
  }
}

export default new INSPECT();







