import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ru from 'vuetify/src/locale/ru.ts';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: {
          base: '#f57f29',
        },
        secondary: {
          base: '#2b4244',
        },
      },
    },
  },
  lang: {
    locales: { ru },
    current: 'ru',
  },
});
