import { map, isArray } from 'lodash-es';
import Api from '@/api';
import { fileItem } from './selectors';

class File extends Api {
  list({ id, section, entity }) {
    return this.rest(
      '/v2/file/info-list.json',
      {
        method: 'POST',
        data: {
          fileId: isArray(id) ? id : [id],
          section,
          entity,
        },
      }
    ).then((result) => map(result, fileItem));
  }
  item({ id }) {
    return this.rest(
      '/file/info.json',
      {
        method: 'POST',
        data: {
          id,
        },
      }
    ).then(fileItem);
  }
  upload(data) {
    return this.rest(
      '/file/upload-file.json',
      {
        method: 'POST',
        data,
      }
    ).then(fileItem);
  }
  downloadFile({ id, section, entity }) {
    return this.download('/v2/file/downloadFile', {
      params: { id, section, entity },
    });
  }
  uploadList(data, {section, entity, ...other}) {
    return this.rest(
      '/v2/file/upload-file-list.json',
      {
        method: 'POST',
        data,
        params: {
          section,
          entity,
          ...other
        }
      }
    ).then((result) => map(result, fileItem));
  }
  delete({ id }) {
    return this.rest(
      '/file/delete.json',
      {
        method: 'POST',
        data: {
          id,
        },
      }
    );
  }
}

export default new File();
