import {get, omitBy, reduce, toNumber, toString} from 'lodash-es';

export default [
  {
    path: '/pkomain/',
    name: 'pkomain/CounterpartyListView',
    meta: { title: 'Список заявок' },
    props: route => ({
      filter: {
        id: toString(get(route, 'query.id', '')),
        inn: toString(get(route, 'query.inn', '')),
        companyName: toString(get(route, 'query.companyName', '')),
        status: toString(get(route, 'query.status', '')),
        company: toString(get(route, 'query.company', '')),
        archive: toString(get(route, 'query.archive', '')),
        isArchive: get(route, 'query.archive', 'N') === 'Y', // Служебный параметр
      },
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/pkomain/CounterpartyListView/CounterpartyListView'),
  },
  {
    path: '/pkomain/registry/',
    name: 'pkomain/RegistryListView',
    meta: { title: 'Реестр контрагентов' },
    props: route => ({
      filter: {
        id: toString(get(route, 'query.id', '')),
        inn: toString(get(route, 'query.inn', '')),
        companyName: toString(get(route, 'query.companyName', '')),
        formId: toString(get(route, 'query.formId', '')),
        company: toString(get(route, 'query.company', '')),
        statusDate: toString(get(route, 'query.statusDate', '')),
        statusEndDate: toString(get(route, 'query.statusEndDate', '')),
        ...reduce(
          omitBy( get(route, 'query'), (val, param) => !~param.indexOf('risk_')),
          (acc, val, param) => ({ ...acc, [param]: val }),
          {}
        ),
      },
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/pkomain/RegistryListView/RegistryListView'),
  },
  {
    path: '/pkomain/:counterparty/:question?',
    name: 'pkomain/CounterpartyDetailView',
    meta: { title: 'Заявка' },
    props: route => ({
      counterparty: toString(get(route, 'params.counterparty')),
      question: toString(get(route, 'params.question')),
      version: toString(get(route, 'query.version', '0')),
    }),
    component: () => import('@/views/pkomain/CounterpartyDetailView/CounterpartyDetailView'),
  },
  {
    path: '/pkomain/:catchAll(.*)',
    redirect: { name: 'pkomain/CounterpartyListView' }
  },
];
