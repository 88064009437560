<template>
  <div :class="$style.root">
    <div class="text-center">
      <a href="/" :class="$style.link">
        <VImg :max-width="width" src="/local/templates/main/static/suply/img/logoesa.png" />
      </a>
      <div :class="$style.title">Единая система регистрации контрагентов</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  computed: {
    width () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 180;
        default: return 221;
      }
    },
  }
}
</script>

<style module lang="scss">
.root {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  @include media-breakpoint-up(md) {

  }
}
.title {
  text-transform: uppercase;
  font-size: 14px;
  display: block;
}
.link {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  margin-bottom: 5px;
}
</style>
