import { get, toNumber, toString } from 'lodash-es';

export default [
  {
    path: '/elkp/',
    name: 'elkp/ContractListView',
    meta: { title: 'Список договоров' },
    props: route => ({
      filter: {
        id: toString(get(route, 'query.id', '')),
        inn: toString(get(route, 'query.inn', '')),
        name: toString(get(route, 'query.name', '')),
        number: toString(get(route, 'query.number', '')),
      },
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/elkp/ContractListView/ContractListView'),
  },
  {
    path: '/elkp/employees/',
    name: 'elkp/EmployeeListView',
    meta: { title: 'Список сотрудников' },
    props: route => ({
      filter: {
        id: toString(get(route, 'query.id')),
        name: toString(get(route, 'query.name')),
        imyapersonalnye_dannye_rabotnikov: toString(get(route, 'query.imyapersonalnye_dannye_rabotnikov')),
        familiyapersonalnye_dannye_rabotnikov: toString(get(route, 'query.familiyapersonalnye_dannye_rabotnikov')),
        otchestvopersonalnye_dannye_rabotnikov: toString(get(route, 'query.otchestvopersonalnye_dannye_rabotnikov')),
        dolzhnostpersonalnye_dannye_rabotnikov: toString(get(route, 'query.dolzhnostpersonalnye_dannye_rabotnikov')),
        authorCompanyName: toString(get(route, 'query.authorCompanyName')),
      },
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/elkp/EmployeeListView/EmployeeListView'),
  },
  {
    path: '/elkp/:contract/',
    name: 'elkp/ContractDetailView',
    meta: { title: 'Договор' },
    props: route => ({
      filter: {
        id: toString(get(route, 'query.id')),
        name: toString(get(route, 'query.name')),
        imyapersonalnye_dannye_rabotnikov: toString(get(route, 'query.imyapersonalnye_dannye_rabotnikov')),
        familiyapersonalnye_dannye_rabotnikov: toString(get(route, 'query.familiyapersonalnye_dannye_rabotnikov')),
        otchestvopersonalnye_dannye_rabotnikov: toString(get(route, 'query.otchestvopersonalnye_dannye_rabotnikov')),
        dolzhnostpersonalnye_dannye_rabotnikov: toString(get(route, 'query.dolzhnostpersonalnye_dannye_rabotnikov')),
        profileId: toString(get(route, 'params.contract')),
      },
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/elkp/ContractDetailView/ContractDetailView'),
  },
  {
    path: '/elkp/linked/:employee/',
    name: 'elkp/ContractLinkView',
    meta: { title: 'Привязка договоров' },
    props: route => ({
      filter: {
        id: toString(get(route, 'query.id', '')),
        inn: toString(get(route, 'query.inn', '')),
        name: toString(get(route, 'query.name', '')),
        number: toString(get(route, 'query.number', '')),
        ['!employeeId']: toString(get(route, 'params.employee')),
      },
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/elkp/ContractLinkView/ContractLinkView'),
  },
  {
    path: '/elkp/linked/:contract/:author/',
    name: 'elkp/EmployeeLinkView',
    meta: { title: 'Привязка сотрудников' },
    props: route => ({
      filter: {
        id: toString(get(route, 'query.id')),
        name: toString(get(route, 'query.name')),
        imyapersonalnye_dannye_rabotnikov: toString(get(route, 'query.imyapersonalnye_dannye_rabotnikov')),
        familiyapersonalnye_dannye_rabotnikov: toString(get(route, 'query.familiyapersonalnye_dannye_rabotnikov')),
        otchestvopersonalnye_dannye_rabotnikov: toString(get(route, 'query.otchestvopersonalnye_dannye_rabotnikov')),
        dolzhnostpersonalnye_dannye_rabotnikov: toString(get(route, 'query.dolzhnostpersonalnye_dannye_rabotnikov')),
        authorId: toString(get(route, 'params.author')),
        ['!profileId']: toString(get(route, 'params.contract')),
      },
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/elkp/EmployeeLinkView/EmployeeLinkView'),
  },
  {
    path: '/elkp/:contract/:employee/:question?',
    alias: [
      '/elkp/employee/:employee/',
    ],
    name: 'elkp/EmployeeDetailView',
    meta: { title: 'Сотрудник' },
    props: route => ({
      id: toString(get(route, 'params.employee')),
      contract: toString(get(route, 'params.contract')),
      question: toString(get(route, 'params.question')),
      filter: {
        id: toString(get(route, 'query.id', '')),
        inn: toString(get(route, 'query.inn', '')),
        name: toString(get(route, 'query.name', '')),
        number: toString(get(route, 'query.number', '')),
        employeeId: toString(get(route, 'params.employee')),
      },
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/elkp/EmployeeDetailView/EmployeeDetailView'),
  },
  {
    path: '/elkp/:catchAll(.*)',
    redirect: { name: 'elkp/ContractListView' }
  },
];
