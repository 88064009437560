import { get, map, toNumber, toString, keyBy } from 'lodash-es';

export const documentDetail = (result) => ({
  id: toString(get(result, 'id', '')), // TODO: пользователь для которого получен
  items: keyBy(map(get(result, 'documents'), (item, index) => ({
    id: toString(get(item, 'id')),
    order: index,
    size: toNumber(get(item, 'size')),
    name: toString(get(item, 'name')),
    format: get(item, 'format', false),
    required: get(item, 'required', false),
    readonly: !get(item, 'isUploadOption', false),
    files: map(get(item, 'files'), item => ({
      id: toString(get(item, 'id')),
      value: get(item, 'value'),
      pending: get(item, 'pending'),
      end: get(item, 'end'),
      name: get(item, 'name'),
      create: get(item, 'create'),
      date: get(item, 'date'),
      actual: !!get(item, 'isActual', false),
      status: toString(get(item, 'status')),
      history: map(get(item, 'history'), (item) => ({
        id: toString(get(item, 'id')),
        value: get(item, 'value'),
        pending: get(item, 'pending'),
        end: get(item, 'end'),
        name: get(item, 'name'),
        date: get(item, 'date'),
        create: get(item, 'create'),
        status: toString(get(item, 'status')),
      })),
    }))
  })), 'id'),
})

export const documentList = (result) => ({
  items: map(get(result, 'items'), (item) => ({
    id: toString(get(item, 'id')),
  })),
  count: toNumber(get(result, 'navCount', 0)),
});
