import Vue from 'vue';
import { map, get, includes, find, isArray, pick } from 'lodash-es';
import api from './api';
const START = 'START';
const END = 'END';
const SET_CURRENT = 'SET_CURRENT';
const SET_PROFILE = 'SET_PROFILE';
const SET_PROFILE_CAPTCHA = 'SET_PROFILE_CAPTCHA';
const SET_LIST = 'SET_LIST';

export default {
  namespaced: true,
  state: {
    pending: {
      fetchCurrent: false,
      logoutUser: false,
      loginUser: false,
      updateProfileDetail: false,
      createProfileDetail: false,
      forgotUser: false,
      fetchEmptyProfileDetail: false,
      changePassword: false,
      editPassword: false,
      fetchList: false,
    },
    current: null,
    list: null,
    profile: null,
  },
  getters: {
    pending(state) {
      return includes(state.pending, true);
    },
    current: (state) => {
      return get(state, 'current');
    },
    profile: (state) => {
      return get(state, 'profile');
    },
    list: (state) => {
      return get(state, 'list');
    },
    hasRole: (state) => {
      return (role, module) => {
        // CONTRAGENT, REVIEWER, CONTRACT_HOLDER
        const modules = get(state, 'current.role');
        const roles = map(module ? pick(modules, [module]) : modules, 'code');
        return !isArray(role) ?
          !!includes(roles, role):
          !!find(roles, (code) => includes(role, code));
      };
    },
    accountStatus(state) {
      return (status) => status === state.current.account.status;
    }
  },
  mutations: {
    [START]: (state, key) => {
      Vue.set(state.pending, key, true);
    },
    [END]: (state, key) => {
      Vue.set(state.pending, key, false);
    },
    [SET_CURRENT]: (state, user) => {
      state.current = user;
    },
    [SET_PROFILE]: (state, current) => {
      state.profile = current;
    },
    [SET_LIST]: (state, list) => {
      state.list = list;
    },
    [SET_PROFILE_CAPTCHA]: (state, captcha) => {
      state.profile.captcha = captcha;
    },
  },
  actions: {
    prefetchUser: async ({ commit }) => {
      const user = await api.current();
      commit(SET_CURRENT, user);
    },
    logoutUser: async ({ commit }) => {
      commit(START, 'logoutUser');
      await api.logout();
      commit(END, 'logoutUser');
      commit(SET_CURRENT, null);
    },
    loginUser: ({ commit }, { login, password, portal, redirect }) => {
      commit(START, 'loginUser');
      return api.login({ login, password, portal, redirect }).then((user) => {
        commit(END, 'loginUser');
        if (user.redirect) {
          window.location = user.redirect;
        }
        commit(SET_CURRENT, user);
        return user;
      }).catch(() => {
        commit(END, 'loginUser');
      });
    },
    forgotUser: ({ commit }, { email, captcha, sid }) => {
      commit(START, 'forgotUser');
      return api.forgot({ email, captcha, sid }).then((user) => {
        commit(END, 'forgotUser');
        return user;
      }).catch((e) => {
        commit(END, 'forgotUser');
        throw e;
      });
    },
    changePasswordUser: ({ commit }, { word, login, password, confirmation, captcha, sid }) => {
      commit(START, 'changePasswordUser');
      return api.changePassword({ word, login, password, confirmation, captcha, sid }).then((user) => {
        commit(END, 'changePasswordUser');
        return user;
      }).catch((e) => {
        commit(END, 'changePasswordUser');
        throw e;
      });
    },
    editPassword: ({ commit }, { currentPassword, newPassword, newPasswordDuplicate }) => {
      commit(START, 'editPassword');
      return api.editPassword({ currentPassword, newPassword, newPasswordDuplicate }).then((user) => {
        commit(END, 'editPassword');
        return user;
      }).catch((e) => {
        commit(END, 'editPassword');
        throw e;
      });
    },
    fetchCurrent: async ({ commit }) => {
      commit(START, 'fetchCurrent');
      const user = await api.current();
      commit(END, 'fetchCurrent');
      commit(SET_CURRENT, user);
      return user;
    },
    fetchList: async ({ commit }, { page, size, filter }) => {
      commit(START, 'fetchList');
      const list = await api.fetchList({ page, size, filter });
      commit(SET_LIST, list);
      commit(END, 'fetchList');
      return list;
    },
    setRole: async ({ commit }, { inspect, pkosmr, pkopnr }) => {
      commit(START, 'setRole');
      const success = await api.setRole({ inspect, pkosmr, pkopnr });
      commit(END, 'setRole');
      return success;
    },
    checkSession: async ({ commit }) => {
      commit(START, 'checkSession');
      const success = await api.checkSession();
      commit(END, 'checkSession');
      return success;
    },
    fetchProfileDetail: ({ commit }) => {
      commit(START, 'fetchProfileDetail');
      return api.profile().then((detail) => {
        commit(SET_PROFILE, detail);
        commit(END, 'fetchProfileDetail');
        return detail;
      }).catch(() => {
        commit(END, 'fetchProfileDetail');
      });
    },
    updateProfileDetail: ({ commit }, { values }) => {
      commit(START, 'updateProfileDetail');
      return api.edit({ values }).then((detail) => {
        commit(SET_PROFILE, detail);
        commit(END, 'updateProfileDetail');
        return detail;
      }).catch(() => {
        commit(END, 'updateProfileDetail');
      });
    },
    createProfileDetail: ({ commit }, { values, captcha, sid }) => {
      commit(START, 'createProfileDetail');
      return api.create({ values, captcha, sid }).then((detail) => {
        commit(SET_PROFILE, detail);
        commit(END, 'createProfileDetail');
        return detail;
      }).catch(() => {
        commit(END, 'createProfileDetail');
        return api.empty().then(({ captcha }) => {
          commit(SET_PROFILE_CAPTCHA, captcha);
        });
      });
    },
    fetchEmptyProfileDetail: ({ commit }) => {
      commit(START, 'fetchEmptyProfileDetail');
      return api.empty().then((detail) => {
        commit(SET_PROFILE, detail);
        commit(END, 'fetchEmptyProfileDetail');
        return detail;
      }).catch(() => {
        commit(END, 'fetchEmptyProfileDetail');
      });
    },
  },
}
