import Api from '@/api';
import {accountDetail, accountTimeLine, accountList} from './selectors';

class Account extends Api {
  getAccountDetail({ id }) {
    return this.rest('/account/get.json', {
      method: 'POST',
      data: { id }
    }).then(accountDetail);
  }

  getAccountList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/account/list.json', {
      method: 'POST',
      data: { page, size, filter },
    }).then(accountList);
  }

  fetchTimeline({ id }) {
    return this.rest('/history/list.json', {
      method: 'POST',
      data: {
        filter: {
          entityId: id,
          entityType: 'ACCOUNT',
          eventType: 'CHANGE',
        }
      }
    }).then(accountTimeLine);
  }

  deleteAccount({ id }) {
    return this.rest('/account/delete.json', {
      method: 'POST',
      data: { id }
    }).then(accountDetail);
  }

  recoverAccount({ id }) {
    return this.rest('/account/recover.json', {
      method: 'POST',
      data: { id }
    }).then(accountDetail);
  }

  changeAuthor({ id, authorId }) {
    return this.rest('/account/change-author.json', {
      method: 'POST',
      data: { id, authorId }
    }).then(accountDetail);
  }
}

export default new Account();







