export default [
  {
    path: '/documents/',
    name: 'docs/DocsDetailView',
    meta: { title: 'Документы' },
    props: () => ({}),
    component: () => import('@/views/docs/DocsDetailView/DocsDetailView'),
  },
  {
    path: '/documents/:catchAll(.*)',
    redirect: { name: 'user/ProfileView' }
  },
];
