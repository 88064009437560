import {get, map, toString, mapValues, toNumber, groupBy, flatten, values} from 'lodash-es';

export const section = item => {
  return {
    mark: toNumber(get(item, 'profileMark', 0)),
    label: toString(get(item, 'label')),
    hint: toString(get(item, 'hint')),
    sections: mapValues(get(item, 'sections'), section),
    questions: mapValues(get(item, 'questions'), (item, code) => ({
      code,
      type: toString(get(item, 'type', 'TEXT')),
      hint: toString(get(item, 'hint', '')),
      label: toString(get(item, 'label')),
      multiple: get(item, 'isMultiple', false),
      changed: !!get(item, 'isChanged', false),
      readonly: !!get(item, 'isReadonly', false),
      options: map(get(item, 'options'), (item) => ({
        text: toString(get(item, 'text')),
        value: toString(get(item, 'value')),
      })),
      values: groupBy(map(get(item, 'values'), (item) => ({
        id: toString(get(item, 'id')),
        type: toString(get(item, 'type', 'TEXT')),
        date: toString(get(item, 'dateExpire')),
        value: toString(get(item, 'value')),
        required: !!get(item, 'isRequired', false),
        readonly: !!get(item, 'isReadonly', false),
        deprecated: !!get(item, 'isDeprecated', false),
      })), 'type'),
    })),
  }
};

// Обратная функция к section
export const sectionPayload = item => ({
  label: toString(get(item, 'label')),
  sections: mapValues(get(item, 'sections'), sectionPayload),
  questions: mapValues(get(item, 'questions'), (item) => ({
    type: toString(get(item, 'type', 'TEXT')),
    label: toString(get(item, 'label')),
    isMultiple: get(item, 'multiple', false),
    isChanged: !!get(item, 'changed', false),
    isReadonly: !!get(item, 'readonly', false),
    options: map(get(item, 'options'), (item) => ({
      text: toString(get(item, 'text')),
      value: toString(get(item, 'value')),
    })),
    values: map(flatten(values(get(item, 'values'))), (item) => ({
      id: toString(get(item, 'id')),
      type: toString(get(item, 'type', 'TEXT')),
      dateExpire: toString(get(item, 'date')),
      value: toString(get(item, 'value')),
      isRequired: !!get(item, 'required', false),
      isReadonly: !!get(item, 'readonly', false),
      isDeprecated: !!get(item, 'deprecated', false),
    })),
  }))
});

export const counterpartyDetail = (result) => {
  return {
    id: toString(get(result, 'id')),
    inn: toString(get(result, 'author.companyInn')),
    name: toString(get(result, 'author.companyName')),
    status: {
      value: toString(get(result, 'status.xmlId')),
      text: toString(get(result, 'status.name')),
    },
    mark: {
      full: {
        average: toNumber(get(result, 'marks.full.averageProfileMark', 0)),
        general: toNumber(get(result, 'marks.full.generalQualificationMark', 0)),
        integral: toNumber(get(result, 'marks.full.integralComprehensiveMark', 0)),
        complexEngineer: toNumber(get(result, 'marks.full.complexEngineerMark', 0)),
        expertMark: toNumber(get(result, 'marks.full.expertMark', 0)),
      },
      simple: {
        average: toNumber(get(result, 'marks.withoutGeneral.averageProfileMark', 0)),
        general: toNumber(get(result, 'marks.withoutGeneral.generalQualificationMark', 0)),
        integral: toNumber(get(result, 'marks.withoutGeneral.integralComprehensiveMark', 0)),
        complexEngineer: toNumber(get(result, 'marks.withoutGeneral.complexEngineerMark', 0)),
        expertMark: toNumber(get(result, 'marks.withoutGeneral.expertMark', 0)),
      },
    },
    items: mapValues(get(result, 'items'), section),
  };
};

export const counterpartyList = (result) => ({
  items: map(get(result, 'items'), (item) => ({
    id: toString(get(item, 'id')),
    inn: toString(get(item, 'author.companyInn')),
    name: toString(get(item, 'author.companyName')),
    status: toString(get(item, 'lastApprovementStatus.name')),
  })),
  count: toNumber(get(result, 'navCount', 0)),
});

export const commentList = (result) => ({
  items: map(result.items, (item) => ({
    id: toString(get(item, 'id')),
    date: toString(get(item, 'dateCreated')),
    author: toString(get(item, 'author.name')),
    question: {
      id: toString(get(item, 'questionXmlId')),
      name: toString(get(item, 'questionText')),
    },
    message: toString(get(item, 'message')),
    draft: get(item, 'isDraft', false),
    actual: get(item, 'isActual', false),
  })),
  count: result.length,
});

export const registryList = (result) => ({
  items: map(get(result, 'items'), (item) => ({
    id: toString(get(item, 'id')),
    inn: toString(get(item, 'author.companyInn')),
    name: toString(get(item, 'author.companyName')),
    status: {
      code: toString(get(item, 'status.xmlId')),
      name: toString(get(item, 'status.name')),
    },
    mark: {
      average: toNumber(get(item, 'averageProfileMark', 0)),
      general: toNumber(get(item, 'generalQualificationMark', 0)),
      integral: toNumber(get(item, 'integralComprehensiveMark', 0)),
      complexEngineer: toNumber(get(item, 'complexEngineerMark', 0)),
      expert: toNumber(get(item, 'expertMark', 0)),
    },
    sections: mapValues(get(item, 'profileMarksBySections'), ({ name, value }) => ({
      name,
      value,
    })),
  })),
  count: toNumber(get(result, 'navCount', 0)),
});
