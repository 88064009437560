import {get, map, toString} from "lodash-es";

export const menuList = (result) => map(get(result, 'items'), (item, index) => {
  return {
    id: index,
    text: toString(get(item, 'TEXT')),
    icon: toString(get(item, 'PARAMS.icon', 'mdi-card-text')),
    link: toString(get(item, 'LINK')),
    selected: !!get(item, 'SELECTED'),
  };
});
