import Api from '@/api';
import {pkoList} from '@/store/pko/selectors';

class PKO extends Api {
  pkoList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/pko/dashboard-list.json', {
      method: 'POST',
      data: { page, size, filter },
    }).then(pkoList);
  }
}

export default new PKO();
