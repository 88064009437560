import Vue from 'vue';
import Api from '@/api';
import { ndaItem } from '@/store/nda/selectors';

class NDA extends Api {
  item({ id, template }) {
    return this.rest(id ? '/nda/get-nda.json' : '/nda/get-empty-nda.json', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: { id },
    }).then(result => {
      const nda = ndaItem(result);
      // Если нет статуса установить NEW как служебный
      if (!nda['BASIS_FOR_NDA']['values'][0]['APPROVAL_STATUS']['value']) {
        nda['BASIS_FOR_NDA']['values'][0]['APPROVAL_STATUS']['options'] = [
          { value: 'NEW', text: 'Новая заявка' },
          ...nda['BASIS_FOR_NDA']['values'][0]['APPROVAL_STATUS']['options'],
        ]
        nda['BASIS_FOR_NDA']['values'][0]['APPROVAL_STATUS']['value'] = 'NEW';
      }
      // Если явно передан темплайт установить его
      if (!nda['BASIS_FOR_NDA']['values'][0]['NDA_TEMPLATE_ID']['value']) {
        nda['BASIS_FOR_NDA']['values'][0]['NDA_TEMPLATE_ID']['value'] = template;
      }
      return nda;
    })
  }
  update({ values }) {
    return this.rest('/nda/save-nda.json', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: {
        values,
      },
    }).then(res => {
      Vue.notify({
        type: 'success',
        title: 'Успех',
        text: 'Форма успешно сохранена',
      });
      return res;
    })
  }
}

export default new NDA();
