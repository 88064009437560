<template>
  <VApp style="position: absolute; width: 0; height: 0;">
    <MainMenuView :name="name" :description="role" />
  </VApp>
</template>

<script>
import { get, head, split, join, uniq, map } from 'lodash-es';
import { mapGetters } from 'vuex';
import MainMenuView from '@/views/general/MainMenuView/MainMenuView';
export default {
  name: 'LayoutNotFound',
  components: {
    MainMenuView,
  },
  computed: {
    ...mapGetters({
      'user': 'user/current',
    }),
    name() {
      return get(this.user, 'short', get(this.user, 'name'));
    },
    role() {
      const name = head(split(this.$route.name, '/'));
      return get(this.user, ['role', name, 'name']) || join(uniq(map(get(this.user, 'role'), 'name')), ', ');
    },
  },
};
</script>

<style lang="scss">
.v-application--wrap {
  .v-app-bar {
    flex: 0 0 auto;
  }
}
</style>
