import Api from '@/api';
import { menuList } from './selectors';

class General extends Api {
  menu() {
    return this.rest(
      '/menu/main.json',
    ).then(menuList);
  }
}

export default new General();

