import {findIndex, get, groupBy, includes} from 'lodash-es';
import api from './api';

const START = 'START';
const END = 'END';

const SET_REGISTRY_LIST = 'SET_REGISTRY_LIST';
const SET_REGISTRY_CURRENT = 'SET_REGISTRY_CURRENT';

const SET_FORM_REGISTRY_LIST = 'SET_FORM_REGISTRY_LIST';
const SET_FORM_REGISTRY_CURRENT = 'SET_FORM_REGISTRY_CURRENT';

const SET_COUNTERPARTY_LIST = 'SET_COUNTERPARTY_LIST';
const SET_COUNTERPARTY_CURRENT = 'SET_COUNTERPARTY_CURRENT';

const SET_COMMENT_LIST = 'SET_COMMENT_LIST';
const SET_COMMENT_CURRENT = 'SET_COMMENT_CURRENT';
const UPDATE_COMMENT = 'UPDATE_COMMENT';

const SET_USER_REVIEW_LIST = 'SET_USER_REVIEW_LIST';

const SET_SURVEY_FORM = 'SET_SURVEY_FORM';
const SET_SURVEY_LIST = 'SET_SURVEY_LIST';

export default {
  namespaced: true,
  state: {
    pending: {
      fetchRegistryList: false,
      fetchFormRegistryList: false,
      fetchCounterpartyList: false,
      fetchCounterpartyDetail: false,
      createCounterparty: false,
      updateCounterparty: false,
      agreementCounterparty: false,
      fetchCommentList: false,
      createComment: false,
      deleteComment: false,
      sendDecision: false,
      downloadRegistryList: false,
      updateReviewer: false,
      fetchUserReviewList: false,
      createUserReview: false,
      fetchSurveyForm: false,
      fetchSurveyList: false,
      saveSurveyForm: false,
      editApprovalTask: false,
    },
    counterparty: {
      current: null,
      list: {
        items: [],
        count: 0,
      }
    },
    registry: {
      current: null,
      list: {
        items: [],
        count: 0,
      },
      userReviewList: {
        items: [],
        count: 0,
      }
    },
    formRegistry: {
      current: null,
      list: {
        items: [],
        count: 0,
      }
    },
    comment: {
      current: null,
      list: {
        items: [],
        count: 0,
      }
    },
    survey: {
      current: null,
      list: {
        items: [],
        count: 0,
      }
    },
  },
  getters: {
    pending: (state) => {
      return includes(state.pending, true);
    },
    counterparty: (state) => {
      return get(state, 'counterparty.current');
    },
    counterparties: (state) => {
      return state.counterparty.list;
    },
    registries: (state) => {
      return state.registry.list;
    },
    formRegistry: (state) => {
      return state.formRegistry.list;
    },
    comment: (state) => {
      return state.comment.current;
    },
    comments: (state) => {
      return state.comment.list;
    },
    commentsByQuestion: (state) => {
      return groupBy(state.comment.list.items, 'question.id');
    },
    userReviews: (state) => {
      return state.registry.userReviewList;
    },
    surveyForm: (state) => {
      return state.survey.current;
    },
    surveyList: (state) => {
      return state.survey.list;
    }
  },
  mutations: {
    [START]: (state, key) => {
      state.pending[key] = true;
    },
    [END]: (state, key) => {
      state.pending[key] = false;
    },
    [SET_COUNTERPARTY_LIST]: (state, list) => {
      state.counterparty.list = list;
    },
    [SET_COUNTERPARTY_CURRENT]: (state, current) => {
      state.counterparty.current = current;
    },
    [SET_REGISTRY_LIST]: (state, list) => {
      state.registry.list = list;
    },
    [SET_REGISTRY_CURRENT]: (state, current) => {
      state.registry.current = current;
    },
    [SET_FORM_REGISTRY_LIST]: (state, list) => {
      state.formRegistry.list = list;
    },
    [SET_FORM_REGISTRY_CURRENT]: (state, current) => {
      state.formRegistry.current = current;
    },
    [SET_COMMENT_LIST]: (state, list) => {
      state.comment.list = list;
    },
    [UPDATE_COMMENT]: (state, { id, message }) => {
      const list = get(state, 'comment.list.items');
      const index = findIndex(list, { id });
      if (~index) {
        const item = get(state, ['comment', 'list', 'items', index]);
        item.message = message;
      }
    },
    [SET_COMMENT_CURRENT]: (state, current) => {
      state.comment.current = current;
    },
    [SET_USER_REVIEW_LIST]: (state, list) => {
      state.registry.userReviewList = list;
    },
    [SET_SURVEY_FORM]: (state, current) => {
      state.survey.current = current;
    },
    [SET_SURVEY_LIST]: (state, list) => {
      state.survey.list = list;
    },
  },
  actions: {
    fetchCounterpartyList: ({ commit }, { page, size, filter }) => {
      commit(START, 'fetchCounterpartyList');
      return api.counterpartyList({ page, size, filter }).then(list => {
        commit(SET_COUNTERPARTY_LIST, list);
        commit(END, 'fetchCounterpartyList');
        return list;
      }).catch(() => {
        commit(END, 'fetchCounterpartyList');
      });
    },
    fetchCounterpartyDetail: ({ commit }, { id }) => {
      commit(START, 'fetchCounterpartyDetail');
      return api.counterpartyDetail({ id }).then((detail) => {
        commit(SET_COUNTERPARTY_CURRENT, detail);
        commit(END, 'fetchCounterpartyDetail');
        return detail;
      }).catch(() => {
        commit(END, 'fetchCounterpartyDetail');
      });
    },
    fetchRegistryList: ({ commit }, { page, size, filter }) => {
      commit(START, 'fetchRegistryList');
      return api.registryList({ page, size, filter }).then(list => {
        commit(END, 'fetchRegistryList');
        commit(SET_REGISTRY_LIST, list)
        return list;
      }).catch(() => {
        commit(END, 'fetchRegistryList');
      });
    },
    downloadRegistryList: ({ commit }, { page, size, filter }) => {
      commit(START, 'downloadRegistryList');
      return api.downloadRegistryList({ page, size, filter }).then(list => {
        commit(END, 'downloadRegistryList');
        return list;
      }).catch(() => {
        commit(END, 'downloadRegistryList');
      });
    },
    fetchFormRegistryList: ({ commit }, { page, size, filter }) => {
      commit(START, 'fetchFormRegistryList');
      return api.formRegistryList({ page, size, filter }).then(list => {
        commit(END, 'fetchFormRegistryList');
        commit(SET_FORM_REGISTRY_LIST, list)
        return list;
      }).catch(() => {
        commit(END, 'fetchFormRegistryList');
      });
    },
    downloadFormRegistryList: ({ commit }, { page, size, filter }) => {
      commit(START, 'downloadFormRegistryList');
      return api.downloadFormRegistryList({ page, size, filter }).then(list => {
        commit(END, 'downloadFormRegistryList');
        return list;
      }).catch(() => {
        commit(END, 'downloadFormRegistryList');
      });
    },
    updateCounterparty: ({ commit }, { id, items }) => {
      commit(START, 'updateCounterparty');
      return api.updateCounterparty({ id, items }).then((detail) => {
        commit(SET_COUNTERPARTY_CURRENT, detail);
        commit(END, 'updateCounterparty');
        return detail;
      }).catch(() => {
        commit(END, 'updateCounterparty');
      })
    },
    agreementCounterparty: ({ commit }, { id }) => {
      commit(START, 'agreementCounterparty');
      return api.agreementCounterparty({ id }).then(() => {
        commit(END, 'agreementCounterparty');
        return true;
      }).catch(() => {
        commit(END, 'agreementCounterparty');
        return false;
      });
    },
    createCounterparty: ({ commit }, { code }) => {
      commit(START, 'createCounterparty');
      return api.createCounterparty({ code }).then((result) => {
        commit(END, 'createCounterparty');
        return result;
      }).catch(() => {
        commit(END, 'createCounterparty');
      });
    },
    fetchCommentList: ({ commit }, { id }) => {
      commit(START, 'fetchCommentList');
      return api.commentList({ id }).then(list => {
        commit(SET_COMMENT_LIST, list);
        commit(END, 'fetchCommentList');
        return list;
      }).catch(() => {
        commit(END, 'fetchCommentList');
      });
    },
    createComment: ({ commit }, { id, parent, question, message }) => {
      commit(START, 'createComment');
      return api.createComment({ id, parent, question, message }).then((result) => {
        commit(END, 'createComment');
        return result;
      }).catch(() => {
        commit(END, 'createComment');
      });
    },
    // Не используется
    approveComment: ({ commit }, { items }) => {
      commit(START, 'approveComment');
      return api.approveComment({ items }).then((result) => {
        commit(END, 'approveComment');
        return result;
      }).catch((e) => {
        commit(END, 'approveComment');
        throw e;
      });
    },
    editComment: ({ commit }, { id, message }) => {
      commit(START, 'editComment');
      return api.editComment({ id, message }).then((result) => {
        commit(UPDATE_COMMENT, { id, message });
        commit(END, 'editComment');
        return result;
      }).catch((e) => {
        commit(END, 'editComment');
        throw e;
      });
    },
    deleteComment: ({ commit }, { id, comments }) => {
      commit(START, 'deleteComment');
      return api.deleteComment({ id, comments }).then((result) => {
        commit(END, 'deleteComment');
        return result;
      }).catch((e) => {
        commit(END, 'deleteComment');
        throw e;
      });
    },
    sendDecision: ({ commit }, { id, decision, comment, notation, expertMark, files }) => {
      commit(START, 'sendDecision');
      return api.sendDecision({ id, decision, comment, notation, expertMark, files }).then(() => {
        commit(END, 'sendDecision');
        return true;
      }).catch(() => {
        commit(END, 'sendDecision');
        return false;
      });
    },
    updateReviewer: ({ commit }, { id }) => {
      commit(START, 'updateReviewer');
      return api.updateReviewer({ id }).then((result) => {
        commit(END, 'updateReviewer');
        return result;
      }).catch((e) => {
        commit(END, 'updateReviewer');
        throw e;
      });
    },
    fetchUserReviewList: ({ commit }, { id }) => {
      commit(START, 'fetchUserReviewList');
      return api.userReviewList({ id }).then(list => {
        commit(SET_USER_REVIEW_LIST, list);
        commit(END, 'fetchUserReviewList');
        return list;
      }).catch(() => {
        commit(END, 'fetchUserReviewList');
      });
    },
    createUserReview: ({ commit }, { id, message }) => {
      commit(START, 'createUserReview');
      return api.createUserReview({ id, message }).then((result) => {
        commit(END, 'createUserReview');
        return result;
      }).catch(() => {
        commit(END, 'createUserReview');
      });
    },
    fetchSurveyList: ({ commit }, { page, size, filter }) => {
      commit(START, 'fetchSurveyList');
      return api.surveyGetList({ page, size, filter }).then(list => {
        commit(SET_SURVEY_LIST, list);
        commit(END, 'fetchSurveyList');
        return list;
      }).catch(() => {
        commit(END, 'fetchSurveyList');
      });
    },
    fetchSurveyForm: ({ commit }, { id }) => {
      commit(START, 'fetchSurveyForm');
      return api.surveyGetForm({ id }).then((detail) => {
        commit(SET_SURVEY_FORM, detail);
        commit(END, 'fetchSurveyForm');
        return detail;
      }).catch(() => {
        commit(END, 'fetchSurveyForm');
      });
    },
    saveSurveyForm: ({ commit }, { id, fields }) => {
      commit(START, 'saveSurveyForm');
      return api.surveySaveForm({ id, fields }).then((detail) => {
        commit(END, 'saveSurveyForm');
        return detail;
      }).catch(() => {
        commit(END, 'saveSurveyForm');
      });
    },
    editApprovalTask: ({ commit }, { id, reviewerId }) => {
      commit(START, 'editApprovalTask');
      return api.editApprovalTask({ id, reviewerId }).then(() => {
        commit(END, 'editApprovalTask');
        return true;
      }).catch(() => {
        commit(END, 'editApprovalTask');
        return false;
      });
    },
  },
}
