import { get, map, toString, toNumber, join, pick, head, pickBy, mapValues, find, merge} from 'lodash-es';
export const shortName = (item) => {
  return join(map(pickBy(pick(item, ['lastName', 'name', 'secondName']), Boolean), (text, i) => i === 'lastName' ? `${text} ` : `${head(text)}.`), '');
}
export const getTextOptionByValue = (item) => {
  const options = get(item, 'options');
  return map(get(item, 'values'), (value) => get(find(options, { value }), 'text'));
}

export const applicationDetail = (result) => {
  const state = get(result, 'status.xmlId');
  return {
    state,
    info: {
      date: toString(get(result, 'dateCreate')),
      author: shortName(get(result, 'author')),
      status: {
        name: toString(get(result, 'status.name')),
        value: toString(get(result, 'status.xmlId')),
      },
      reviewer: head(getTextOptionByValue(get(result, 'reviewer'))),
      authorId: toString(get(result, 'author.id')),
    },
    inspectorSlavesCount: get(result, 'inspectorSlavesCount', 0),
    comments: get(result, 'comments', {
      total: 0,
      active: 0,
    }),
    messages: get(result, 'messages', {
      total: 0,
      active: get(result, 'activeMessages', 0),
    }),
    values: mapValues(pickBy(result, 'values'), (item) => ({
      label: get(item, 'label', ''),
      hint: get(item, 'hint', ''),
      values: get(item, 'values', []),
      options: map(get(item, 'options'), (item) => merge({
        text: get(item, 'email'),
        value: get(item, 'id'),
      }, item)),
      readonly: get(item, 'isReadonly', false),
    })),
    feedback: {
      date: toString(get(result, 'feedback.dateCreate')),
      comment: toString(get(result, 'feedback.comment')),
      mark: toString(get(result, 'feedback.mark')),
      inspector: get(result, 'feedback.inspectorName'),
      author: shortName(get(result, 'feedback.author')),
    },
  }
};

export const inspectorList = (result) => {
  return {
    items: map(get(result, 'items'), (item) => ({
      id: toString(get(item, 'id')),
      email: toString(get(item, 'email')),
      company: toString(get(item, 'fullCompanyName')),
      inn: toString(get(item, 'inn')),
      name: toString(get(item, 'name')),
      rating: toString(get(item, 'rating')),
      work: toString(get(item, 'inWorkInspections')),
      inspections: toString(get(item, 'totalInspections')),
      rejects: toString(get(item, 'rejects')),
      type: {
        value: toString(get(item, 'type.xmlId')),
        text: toString(get(item, 'type.name')),
      },
    })),
    count: toNumber(get(result, 'navCount', 0)),
  };
};

export const applicationList = (result) => {
  return {
    dictionary: {
      productCategory: map(get(result, ['dictionary', 'productCategory']), ({ name, value, text }) => ({
        name: toString(name),
        value: toString(value),
        text: toString(text),
      }))
    },
    items: map(get(result, 'items'), (item) => ({
      id: toString(get(item, 'id')),
      date: toString(get(item, 'dateCreate')),
      inspection: join([toString(get(item, 'inspectionDateStart')), toString(get(item, 'inspectionDateEnd'))], ' - '),
      author: shortName(get(item, 'author')),
      supply: toString(get(item, 'productSupplyName')),
      inn: toString(get(item, 'productSupplyInn')),
      company: toString(get(item, 'company.name')),
      workshop: get(item, 'workshop', []),
      reviewer: shortName(get(item, 'reviewer')),
      workshopReviewer: shortName(get(item, 'workshopReviewer')),
      type: get(item, 'inspectionTypes', []),
      products: get(item, 'productCategory', []),
      inspector: toString(get(item, 'inspector.companyName')) || 'Не выбрано',
      status: {
        name: toString(get(item, 'status.name')),
        value: toString(get(item, 'status.xmlId')),
      },
      comments: get(item, 'comments', {
        total: 0,
        active: 0,
      }),
      messages: get(item, 'messages', {
        total: 0,
        active: get(item, 'activeMessages', 0),
      }),
      persons: {
        initiator: toString(get(item, 'author.id')),
        inspector: toString(get(item, 'inspector.id')),
        reviewer: toString(get(item, 'reviewer.id')),
        workshopReviewer: toString(get(item, 'workshopReviewer.id')),
      },
    })),
    count: toNumber(get(result, 'navCount', 0)),
  }
};

export const commentList = (result) => ({
  items: map(result.items, (item) => ({
    id: toString(get(item, 'id')),
    code: toString(get(item, 'code')),
    type: toString(get(item, 'type')),
    parent: toString(get(item, 'parentId')),
    author: {
      id: toString(get(item, 'author.id')),
      name: shortName(get(item, 'author')),
    },
    application: toString(get(item, 'applicationId')),
    date: toString(get(item, 'dateCreate')),
    message: toString(get(item, 'comment')),
    draft: get(item, 'isDraft', false),
    actual: get(item, 'isActual', false),
  })),
  count: result.length,
});

export const timelineList = (result) => map(get(result, 'items'), (item) => ({
  id: toString(get(item, 'id')),
  author: shortName(get(item, 'author')),
  old: get(item, 'oldValue', ''),
  new: get(item, 'newValue', ''),
  date: get(item, 'date', ''),
  comment: get(item, 'comment', ''),
}));
