import { set, get, toNumber, toString, pickBy, reduce, omit } from 'lodash-es';

export default [
  {
    path: '/pkopnr/',
    name: 'pkopnr/CounterpartyListView',
    meta: { title: 'Список заявок' },
    props: route => ({
      filter: {
        ...omit( get(route, 'query'), ['page', 'size']),
      },
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/pkopnr/CounterpartyListView/CounterpartyListView'),
  },
  {
    path: '/pkopnr/registry/',
    name: 'pkopnr/RegistryListView',
    meta: { title: 'Реестр контрагентов' },
    props: route => ({
      ...reduce(pickBy(get(route, 'query'), (value, key) => !!~key.indexOf('filter.')), (acc, value, path) => {
        if (value) set(acc, path, value);
        return acc;
      }, {}),
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/pkopnr/RegistryListView/RegistryListView'),
  },
  {
    path: '/pkopnr/form-registry/',
    name: 'pkopnr/FormRegistryListView',
    meta: { title: 'Реестр заявок' },
    props: route => ({
      filter: {
        id: toString(get(route, 'query.id', '')),
        name: toString(get(route, 'query.name', '')),
        email: toString(get(route, 'query.email', '')),
        phone: toString(get(route, 'query.phone', '')),
        status: toString(get(route, 'query.status', '')),
        inn: toString(get(route, 'query.inn', '')),
      },
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/pkopnr/FormRegistryListView/FormRegistryListView'),
  },
  {
    path: '/pkopnr/:counterparty',
    name: 'pkopnr/CounterpartyDetailView',
    meta: { title: 'Заявка' },
    props: route => ({
      counterparty: toString(get(route, 'params.counterparty')),
      question: toString(get(route, 'params.question'))
    }),
    component: () => import('@/views/pkopnr/CounterpartyDetailView/CounterpartyDetailView'),
  },
  {
    path: '/pkopnr/:counterparty/:question',
    name: 'pkopnr/CounterpartyCommentsView',
    meta: { title: 'Комментарии' },
    props: route => ({
      counterparty: toString(get(route, 'params.counterparty')),
      question: toString(get(route, 'params.question'))
    }),
    component: () => import('@/views/pkopnr/CounterpartyDetailView/CounterpartyDetailView'),
  },
  {
    path: '/pkopnr/:catchAll(.*)',
    redirect: { name: 'pkopnr/CounterpartyListView' }
  },
];
