import { capitalize, get, map, toNumber, toString, reduce, merge, forEach, keyBy, concat, values, omit, mapValues } from 'lodash-es';

export const contractList = (result) => ({
  items: map(get(result, 'items'), (item) => ({
    id: toString(get(item, 'id')),
    date: toString(get(item, 'contractExpireDate')),
    number: toString(get(item, 'contractNumber')),
    inn: toString(get(item, 'author.companyInn')),
    name: toString(get(item, 'author.companyName')),
    hours: map(get(item, 'workHours'), item => ({
      disabled: !get(item, 'canEdit', true),
      month: capitalize(toString(get(item, 'monthName'))),
      value: get(item, 'value', true),
      current: get(item, 'isCurrent', false),
    })),
    employees: toNumber(get(item, 'employeesCount', 0)),
    comments: toNumber(get(item, 'actualComments', 0)),
  })),
  count: toNumber(get(result, 'navCount', 0)),
});
export const contractDetail = (result) => ({
  id: toString(get(result, 'id')),
  date: toString(get(result, 'contractExpireDate')),
  number: toString(get(result, 'contractNumber')),
  inn: toString(get(result, 'author.companyInn')),
  name: toString(get(result, 'author.companyName')),
  author: toString(get(result, 'author.id')),
  hours: keyBy(map(get(result, 'workHours'), item => ({
    id: toString(get(item, 'value')),
    disabled: !get(item, 'canEdit', true),
    month: capitalize(toString(get(item, 'monthName'))),
    value: toNumber(get(item, 'value', 0)),
    current: get(item, 'isCurrent', false),
  })), 'month'),
});

export const employeeValues = (result) => {
  return reduce(result, (accumulator, item) => {
    const id = toString(get(item, 'id'));
    const question = toString(get(item, 'questionId'));
    const code = toString(get(item, 'code'));
    let type = toString(get(item, 'answerType', 'TEXT'));
    if (~code.indexOf('email')) type = 'EMAIL';
    if (~code.indexOf('telefon')) type = 'PHONE';
    const section = toString(get(item, 'sectionCode'));
    const multiple = get(item, 'isMultiple', false);
    const order = toNumber(get(item, 'order', 0));
    const label = toString(get(item, 'text'));
    const value = toString(get(item, 'value'));
    const values = get(accumulator, [section, 'questions', code, 'values', type], []);
    return merge(accumulator, {
      [section]: {
        order,
        label: toString(get(item, 'sectionName')),
        questions: {
          [code]: {
            code,
            order,
            label,
            multiple,
            question,
            values: {
              [type]: concat(values, [{
                id,
                type,
                code,
                order,
                label,
                value,
                section,
                question,
                ...type === 'FILE' ? {
                  min: new Date().toISOString(),
                  date: toString(get(item, 'expireDate')),
                  dated: get(item, 'needSetExpireDate', false),
                  needDated: get(item, 'needSetExpireDate', false),
                } : {},
              }])
            },
          }
        },
      }
    });
  }, {});
};
// Обратная функция к employeeValues
export const employeeData = (result) => {
  const acc = [];
  forEach(result, ({ questions }) => {
    forEach(questions, ({ values }) => {
      forEach(values, (values) => {
        forEach(values, ({ id, code, value, date, type, question }) => {
          acc.push({
            id,
            code,
            value,
            question,
            ... type === 'FILE' ? {
              expireDate: date,
            } : {},
          });
        })
      })
    })
  });
  return acc;
};
export const employeeList = (result) => ({
  items: map(get(result, 'items'), (item) => ({
    id: toString(get(item, 'id')),
    comments: toNumber(get(item, 'actualComments', 0)),
    company: {
      name: toString(get(item, 'author.companyName')),
    },
    values: keyBy(item.values, 'code'),
  })),
  count: toNumber(get(result, 'navCount', 0)),
})
export const employeeDetail = (result, fields = []) => {
  const employee = result;
  const order = mapValues(keyBy(map(fields, ({code},  order) => ({ order, code })), 'code'), ({order}) => order);
  const fieldsWithIndex = map(fields, (item) => ({ order: order[item.code], ...item, id: '' }));
  const valuesWithIndex = map(employee.values, (item) => ({ order: order[item.code], ...item }));
  const omitKeys = map(valuesWithIndex, 'code');
  const fullEmployeeValues = concat(valuesWithIndex, values(omit(keyBy(fieldsWithIndex, 'code'), omitKeys)));
  return {
    id: toString(get(result, 'id')),
    values: employeeValues(fullEmployeeValues),
  }
};

export const commentList = (result) => ({
  items: map(result, (item) => ({
    id: toString(get(item, 'id')),
    date: toString(get(item, 'dateCreated')),
    author: toString(get(item, 'author.name')),
    question: toString(get(item, 'questionId')),
    message: toString(get(item, 'message')),
    actual: get(item, 'isActual', false),
    draft: get(item, 'isDraft', false),
  })),
  count: result.length,
})
