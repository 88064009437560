import { get, toNumber, pickBy, toString } from 'lodash-es';

export default [
  {
    path: '/actsvr/',
    name: 'actsvr/AcceptanceListView',
    meta: { title: 'Список актов о выполненной работе' },
    props: route => ({
      filter: pickBy({
        id: toString(get(route, 'query.id', '')),
        status: toString(get(route, 'query.status', '')),
        updated: toString(get(route, 'query.updated', '')),
        contract: toString(get(route, 'query.contract', '')),
        agreement: toString(get(route, 'query.agreement', '')),
        work: toString(get(route, 'query.work', '')),
        place: toString(get(route, 'query.place', '')),
        initiator: toString(get(route, 'query.initiator', '')),
      }, Boolean),
      page: toNumber(get(route, 'query.page', 1)),
      size: toNumber(get(route, 'query.size', 20)),
    }),
    component: () => import('@/views/actsvr/AcceptanceListView/AcceptanceListView'),
  },
  {
    path: '/actsvr/create',
    name: 'actsvr/AcceptanceCreateView',
    meta: { title: 'Новое согласование актов о выполненной работе' },
    component: () => import('@/views/actsvr/AcceptanceDetailView/AcceptanceDetailView'),
  },
  {
    path: '/actsvr/:acceptance',
    name: 'actsvr/AcceptanceDetailView',
    meta: { title: 'Согласование актов о выполненной работе' },
    props: route => ({
      acceptance: toString(get(route, 'params.acceptance')),
    }),
    component: () => import('@/views/actsvr/AcceptanceDetailView/AcceptanceDetailView'),
  },
  {
    path: '/actsvr/:catchAll(.*)',
    redirect: { name: 'actsvr/AcceptanceListView' }
  },
];
