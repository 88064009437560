import { get, includes} from 'lodash-es';
import api from './api';

const START = 'START';
const END = 'END';

const SET_DOCUMENT_LIST = 'SET_DOCUMENT_LIST';
const SET_DOCUMENT_CURRENT = 'SET_DOCUMENT_CURRENT';

export default {
  namespaced: true,
  state: {
    pending: {
      fetchDocumentList: false,
      fetchDocumentDetail: false,
      createDocument: false,
    },
    document: {
      current: null,
      list: {
        items: [],
        count: 0,
      },
    },
  },
  getters: {
    pending: (state) => {
      return includes(state.pending, true);
    },
    document: (state) => {
      return get(state, 'document.current');
    },
    acceptances: (state) => {
      return state.document.list;
    },
  },
  mutations: {
    [START]: (state, key) => {
      state.pending[key] = true;
    },
    [END]: (state, key) => {
      state.pending[key] = false;
    },
    [SET_DOCUMENT_LIST]: (state, list) => {
      state.document.list = list;
    },
    [SET_DOCUMENT_CURRENT]: (state, current) => {
      state.document.current = current;
    },
  },
  actions: {
    fetchDocumentList: ({ commit }, { page, size, filter }) => {
      commit(START, 'fetchDocumentList');
      return api.documentList({ page, size, filter }).then(list => {
        commit(SET_DOCUMENT_LIST, list);
        commit(END, 'fetchDocumentList');
        return list;
      }).catch(() => {
        commit(END, 'fetchDocumentList');
      });
    },
    fetchDocumentDetail: ({ commit }, { id }) => {
      commit(START, 'fetchDocumentDetail');
      return api.documentDetail({ id }).then((detail) => {
        commit(SET_DOCUMENT_CURRENT, detail);
        commit(END, 'fetchDocumentDetail');
        return detail;
      }).catch(() => {
        commit(END, 'fetchDocumentDetail');
      });
    },
    createDocument: ({ commit }, payload) => {
      commit(START, 'createDocument');
      return api.createDocument(payload).then(result => {
        commit(END, 'createDocument');
        return result;
      }).catch(() => {
        commit(END, 'createDocument');
      });
    },
  },
}
