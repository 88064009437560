import {get, map, mapValues, merge, pickBy, toNumber, toString} from 'lodash-es';
import {shortName} from '@/store/inspect/selectors';

export const applicationList = (result) => ({
  items: map(get(result, 'items'), (item) => ({
    id: toString(get(item, 'id')),
    inn: toString(get(item, 'user.companyInn')),
    ogrn: toString(get(item, 'user.companyOgrn')),
    name: toString(get(item, 'user.companyName')),
    status: {
      name: toString(get(item, 'status.name')),
      value: toString(get(item, 'status.xmlId')),
    },
    codeMdg: toString(get(item, 'codeMdg.values.0')),
    contractNumber: toString(get(item, 'contractNumber.values.0')),
  })),
  count: toNumber(get(result, 'navCount', 0)),
});

export const applicationDetail = (result) => {
  const state = get(result, 'status.xmlId');
  return {
    state,
    id: toString(get(result, 'id')),
    info: {
      inn: toString(get(result, 'user.companyInn')),
      ogrn: toString(get(result, 'user.companyOgrn')),
      name: toString(get(result, 'user.companyName')),
      status: {
        name: toString(get(result, 'status.name')),
        value: toString(get(result, 'status.xmlId')),
      },
      authorId: toString(get(result, 'author.id')),
    },
    values: mapValues(pickBy(result, 'values'), (item) => ({
      label: get(item, 'label', ''),
      hint: get(item, 'hint', ''),
      values: get(item, 'values', []),
      options: map(get(item, 'options'), (item) => merge({
        text: get(item, 'email'),
        value: get(item, 'id'),
      }, item)),
      readonly: get(item, 'isReadonly', false),
    })),
  }
};

export const approvalHistoryList = (result) => map(get(result, 'items'), (item, index) => {
  return {
    id: index,
    author: shortName(get(item, 'author')),
    status: toString(get(item, 'status.name')),
    code: toString(get(item, 'status.xmlId')),
    publicComment: toString(get(item, 'publicComment', '')),
    date: toString(get(item, 'dateCreate', '')),
    role: get(item, 'reviewerName', ) || 'Проверяющий',
  };
});