import {get, includes} from 'lodash-es';
import api from './api';

const START = 'START';
const END = 'END';

const SET_PKO_LIST = 'SET_PKO_LIST';

export default {
  namespaced: true,
  state: {
    pending: {
      fetchPkoList: false,
    },
    pko: {
      current: null,
      list: {
        additional: {
          title: '',
          messages: [],
        },
        items: [],
        count: 0,
      }
    }
  },
  getters: {
    pending: (state) => {
      return includes(state.pending, true);
    },
    pkoList: (state) => {
      return get(state, 'pko.list', []);
    },
  },
  mutations: {
    [START]: (state, key) => {
      state.pending[key] = true;
    },
    [END]: (state, key) => {
      state.pending[key] = false;
    },
    [SET_PKO_LIST]: (state, list) => {
      state.pko.list = list;
    },
  },
  actions: {
    fetchPkoList: ({ commit }) => {
      commit(START, 'fetchPkoList');
      return api.pkoList({}).then(list => {
        commit(END, 'fetchPkoList');
        commit(SET_PKO_LIST, list)
        return list;
      }).catch(() => {
        commit(END, 'fetchPkoList');
      });
    },
  },
};
