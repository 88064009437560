import Vue from 'vue';
import Vuex from 'vuex';
import nda from './nda';
import account from './account';
import accountApplication from './account-application';
import accountUsers from './account-users';
import user from './user';
import file from './file';
import elkp from './elkp';
import docs from './docs';
import actsvr from './actsvr';
import pkopir from './pkopir';
import pkosmr from './pkosmr';
import pkomain from './pkomain';
import pkopnr from './pkopnr';
import inspect from './inspect';
import general from './general';
import pko from './pko';
import spa from './spa';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    nda,
    account,
    accountApplication,
    accountUsers,
    user,
    file,
    elkp,
    docs,
    actsvr,
    pkopir,
    pkosmr,
    pkomain,
    pkopnr,
    inspect,
    general,
    pko,
    spa,
  }
})
