import {get, groupBy, includes} from 'lodash-es';
import api from './api';

const START = 'START';
const END = 'END';

const SET_REGISTRY_LIST = 'SET_REGISTRY_LIST';
const SET_REGISTRY_CURRENT = 'SET_REGISTRY_CURRENT';

const SET_COUNTERPARTY_LIST = 'SET_COUNTERPARTY_LIST';
const SET_COUNTERPARTY_CURRENT = 'SET_COUNTERPARTY_CURRENT';

const SET_COMMENT_LIST = 'SET_COMMENT_LIST';
const SET_COMMENT_CURRENT = 'SET_COMMENT_CURRENT';

export default {
  namespaced: true,
  state: {
    pending: {
      fetchRegistryList: false,
      fetchCounterpartyList: false,
      fetchCounterpartyDetail: false,
      createCounterparty: false,
      updateCounterparty: false,
      agreementCounterparty: false,
      fetchCommentList: false,
      createComment: false,
      deleteComment: false,
      sendDecision: false,
      downloadRegistryList: false,
    },
    counterparty: {
      current: null,
      list: {
        items: [],
        count: 0,
      }
    },
    registry: {
      current: null,
      list: {
        items: [],
        count: 0,
      }
    },
    comment: {
      current: null,
      list: {
        items: [],
        count: 0,
      }
    },
  },
  getters: {
    pending: (state) => {
      return includes(state.pending, true);
    },
    counterparty: (state) => {
      return get(state, 'counterparty.current');
    },
    counterparties: (state) => {
      return state.counterparty.list;
    },
    registries: (state) => {
      return state.registry.list;
    },
    comment: (state) => {
      return state.comment.current;
    },
    comments: (state) => {
      return groupBy(state.comment.list.items, 'question.id');
    },
  },
  mutations: {
    [START]: (state, key) => {
      state.pending[key] = true;
    },
    [END]: (state, key) => {
      state.pending[key] = false;
    },
    [SET_COUNTERPARTY_LIST]: (state, list) => {
      state.counterparty.list = list;
    },
    [SET_COUNTERPARTY_CURRENT]: (state, current) => {
      state.counterparty.current = current;
    },
    [SET_REGISTRY_LIST]: (state, list) => {
      state.registry.list = list;
    },
    [SET_REGISTRY_CURRENT]: (state, current) => {
      state.registry.current = current;
    },
    [SET_COMMENT_LIST]: (state, list) => {
      state.comment.list = list;
    },
    [SET_COMMENT_CURRENT]: (state, current) => {
      state.comment.current = current;
    },
  },
  actions: {
    fetchCounterpartyList: ({ commit }, { page, size, filter }) => {
      commit(START, 'fetchCounterpartyList');
      return api.counterpartyList({ page, size, filter }).then(list => {
        commit(SET_COUNTERPARTY_LIST, list);
        commit(END, 'fetchCounterpartyList');
        return list;
      }).catch(() => {
        commit(END, 'fetchCounterpartyList');
      });
    },
    fetchCounterpartyDetail: ({ commit }, { id }) => {
      commit(START, 'fetchCounterpartyDetail');
      return api.counterpartyDetail({ id }).then((detail) => {
        commit(SET_COUNTERPARTY_CURRENT, detail);
        commit(END, 'fetchCounterpartyDetail');
        return detail;
      }).catch(() => {
        commit(END, 'fetchCounterpartyDetail');
      });
    },
    fetchRegistryList: ({ commit }, { page, size, filter }) => {
      commit(START, 'fetchRegistryList');
      return api.registryList({ page, size, filter }).then(list => {
        commit(END, 'fetchRegistryList');
        commit(SET_REGISTRY_LIST, list)
        return list;
      }).catch(() => {
        commit(END, 'fetchRegistryList');
      });
    },
    downloadRegistryList: ({ commit }, { page, size, filter }) => {
      commit(START, 'downloadRegistryList');
      return api.downloadRegistryList({ page, size, filter }).then(list => {
        commit(END, 'downloadRegistryList');
        return list;
      }).catch(() => {
        commit(END, 'downloadRegistryList');
      });
    },
    updateCounterparty: ({ commit }, { id, items }) => {
      commit(START, 'updateCounterparty');
      return api.updateCounterparty({ id, items }).then((detail) => {
        commit(SET_COUNTERPARTY_CURRENT, detail);
        commit(END, 'updateCounterparty');
        return detail;
      }).catch(() => {
        commit(END, 'updateCounterparty');
      })
    },
    agreementCounterparty: ({ commit }, { id }) => {
      commit(START, 'agreementCounterparty');
      return api.agreementCounterparty({ id }).then(() => {
        commit(END, 'agreementCounterparty');
        return true;
      }).catch(() => {
        commit(END, 'agreementCounterparty');
        return false;
      });
    },
    createCounterparty: ({ commit }) => {
      commit(START, 'createCounterparty');
      return api.createCounterparty().then((result) => {
        commit(END, 'createCounterparty');
        return result;
      }).catch(() => {
        commit(END, 'createCounterparty');
      });
    },
    fetchCommentList: ({ commit }, { id }) => {
      commit(START, 'fetchCommentList');
      return api.commentList({ id }).then(list => {
        commit(SET_COMMENT_LIST, list);
        commit(END, 'fetchCommentList');
        return list;
      }).catch(() => {
        commit(END, 'fetchCommentList');
      });
    },
    createComment: ({ commit }, { id, question, message }) => {
      commit(START, 'createComment');
      return api.createComment({ id, question, message }).then((result) => {
        commit(END, 'createComment');
        return result;
      }).catch(() => {
        commit(END, 'createComment');
      });
    },
    deleteComment: ({ commit }, { id, comments }) => {
      commit(START, 'deleteComment');
      return api.deleteComment({ id, comments }).then((result) => {
        commit(END, 'deleteComment');
        return result;
      }).catch(() => {
        commit(END, 'deleteComment');
      });
    },
    sendDecision: ({ commit }, { id, decision, comment, notation, expertMark }) => {
      commit(START, 'sendDecision');
      return api.sendDecision({ id, decision, comment, notation, expertMark }).then(() => {
        commit(END, 'sendDecision');
        return true;
      }).catch(() => {
        commit(END, 'sendDecision');
        return false;
      });
    }
  },
}
