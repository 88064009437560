import { get, map, toNumber, toString } from 'lodash-es';

export const acceptanceDetail = (result) => ({
  id: get(result, 'id', ''),
  place: get(result, 'place', ''),
  work: get(result, 'work', ''),
  status: get(result, 'status', ''),
  topo: get(result, 'topo', ''),
  spp: get(result, 'spp', ''),
  mvz: get(result, 'mvz', ''),
  contract: get(result, 'contract', ''),
  agreement: get(result, 'agreement', ''),
  sed: get(result, 'sed', ''),
  created: get(result, 'created', ''),
  updated: get(result, 'updated', ''),
  contractor: get(result, 'contractor', {
    id: '',
    inn: '',
    kpp: '',
    ksk: '',
    tax: '',
  }),
  initiator: get(result, 'initiator', {
    name: '',
    phone: '',
    email: '',
    position: '',
  }),
  files: get(result, 'files', []),
  notification: get(result, 'notification', {
    time: '',
    type: '',
  }),
})

export const acceptanceList = (result) => ({
  items: map(get(result, 'items'), (item) => ({
    id: toString(get(item, 'id')),
    status: toString(get(item, 'status')),
    updated: toString(get(item, 'updated')),
    contract: toString(get(item, 'contract')),
    agreement: toString(get(item, 'agreement')),
    work: toString(get(item, 'work')),
    place: toString(get(item, 'place')),
    initiator: toString(get(item, 'initiator')),
  })),
  count: toNumber(get(result, 'navCount', 0)),
});
