import { omitBy, isEmpty } from 'lodash-es';
import Api from '@/api';
import {
  applicationDetail,
  applicationList,
  approvalHistoryList,
} from './selectors';

class SPA extends Api {
  applicationList({ page = 1, size = 10, filter = {} }) {
    return this.rest('/spa/access-application/list.json', {
      method: 'POST',
      data: { page, size, filter: omitBy(filter, isEmpty) },
    }).then(applicationList);
  }
  applicationDetailWithOutApproval({ id }) {
    return this.rest('/spa/access-application/get.json',{
      method: 'POST',
      data: {
        id,
      },
    })
      .then(applicationDetail);
  }
  applicationDetail({ id }) {
    return Promise.all([
      this.applicationDetailWithOutApproval({ id }),
      this.approvalHistory({ id }),
    ]).then(([application, approvalHistory]) => {
      return {
        approvalHistory,
        ...application,
      }
    });
  }
  approvalHistory({ id }) {
    return this.rest('/spa/access-application/timeline.json', {
      method: 'POST',
      data: {
        id: id,
      },
    }).then(approvalHistoryList)
  }
  updateApplicationWithoutApproval({ id, fields }) {
    return this.rest('/spa/access-application/edit.json', {
      method: 'POST',
      data: {
        item: {
          id,
          ...fields,
        },
      }
    }).then(applicationDetail);
  }
  updateApplication({ id, fields }) {
    return Promise.all([
      this.updateApplicationWithoutApproval({ id, fields }),
      this.approvalHistory({ id }),
    ]).then(([application, approvalHistory]) => {
      return {
        approvalHistory,
        ...application,
      }
    });
  }
  approveActionApplication({ id, action, comment }) {
    return this.rest('/spa/access-application/apply-approve-action.json', {
      method: 'POST',
      data: {
        id,
        action,
        comment
      },
    });
  }
}

export default new SPA();
