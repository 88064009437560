import { includes, get } from 'lodash-es';
import api from '@/store/general/api';
const START = 'START';
const END = 'END';
const SET_MENU = 'SET_ITEMS';

export default {
  namespaced: true,
  state: {
    pending: {
      fetchMenuItems: false,
    },
    menu: [],
  },
  getters: {
    pending(state) {
      return includes(state.pending, true);
    },
    menu(state) {
      return get(state, 'menu', []);
    }
  },
  mutations: {
    [START]: (state, key) => {
      state.pending[key] = true;
    },
    [END]: (state, key) => {
      state.pending[key] = false;
    },
    [SET_MENU]: (state, items) => {
      state.menu = items;
    }
  },
  actions: {
    fetchMenuItems: ({ commit }) => {
      commit(START, 'fetchMenuItems');
      return api.menu().then((items) => {
        commit(END, 'fetchMenuItems');
        commit(SET_MENU, items);
        return items;
      }).catch((e) => {
        commit(END, 'fetchMenuItems');
        throw e;
      });

    },
  },
}
