<template>
  <VApp>
    <MainMenuView :name="name" :description="role" />
    <VMain>
      <VAppBar elevation="0" color="#f7f7f7">
        <Logo />
        <UserThumbnail v-if="user && !mobile" :name="name" :description="role" />
      </VAppBar>
      <VContainer fluid>
        <router-view />
      </VContainer>
      <NotificationLog />
    </VMain>
  </VApp>
</template>

<script>
import { get, head, split, join, uniq, map, debounce } from 'lodash-es';
import { mapGetters } from 'vuex';
import Logo from '@/components/general/Logo/Logo';
import MainMenuView from '@/views/general/MainMenuView/MainMenuView';
import NotificationLog from '@/views/general/NotificationLog/NotificationLog';
import UserThumbnail from '@/components/general/UserThumbnail/UserThumbnail';
export default {
  name: 'LayoutDefault',
  components: {
    Logo,
    MainMenuView,
    UserThumbnail,
    NotificationLog,
  },
  data() {
    return {
      timer: null,
    }
  },
  computed: {
    ...mapGetters({
      'user': 'user/current',
    }),
    name() {
      return get(this.user, 'short', get(this.user, 'name'));
    },
    role() {
      const name = head(split(this.$route.name, '/'));
      if (name === 'user') return get(this.user, 'isEvrazEmployee') ? 'Сотрудник ЕВРАЗА' : null;
      return get(this.user, ['role', name, 'name']) || join(uniq(map(get(this.user, 'role'), 'name')), ', ');
    },
    mobile() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    title() {
      return get(this.$route, 'meta.title', '');
    },
  },
  methods: {
    resize: debounce(function() {
      const meta = document.head.querySelector('meta[name="viewport"]');
      meta.content = window.outerWidth > 830 ?
          'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, minimal-ui':
          'width=830'
    }, 500),
  },
  watch: {
    title: {
      immediate: true,
      handler(title) {
        if (title) document.title = title;
      }
    },
  },
  beforeMount() {
    this.resize();
    window.addEventListener('resize', this.resize, { passive: true });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
  },
};
</script>

<style lang="scss">
.notifications {

}
.clearfix {
  &:before,
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}
.v-application--wrap {
  .v-app-bar {
    flex: 0 0 auto;
  }
}
</style>
