import api from './api';
import { employeeData } from './selectors';
import {includes, groupBy, get, keyBy, mapValues} from 'lodash-es';

const START = 'START';
const END = 'END';
const SET_CONTRACT_LIST = 'SET_CONTRACT_LIST';
const SET_CONTRACT_CURRENT = 'SET_CONTRACT_CURRENT';

const SET_EMPLOYEE_LIST = 'SET_EMPLOYEE_LIST';
const SET_EMPLOYEE_CURRENT = 'SET_EMPLOYEE_CURRENT';

const SET_COMMENT_LIST = 'SET_COMMENT_LIST';
const SET_COMMENT_CURRENT = 'SET_COMMENT_CURRENT';

export default {
  namespaced: true,
  state: {
    pending: {
      fetchContractList: false,
      fetchContractDetail: false,
      createContract: false,
      deleteContract: false,
      setWorkTimeToContract: false,
      fetchEmployeeList: false,
      fetchEmployeeDetail: false,
      deleteEmployee: false,
      updateEmployee: false,
      createEmployee: false,
      removeEmployeeFromContract: false,
      addEmployeeToContract: false,
      fetchCommentList: false,
      viewedComments: false,
    },
    contract: {
      current: null,
      list: {
        items: [],
        count: 0,
      }
    },
    employee: {
      current: null,
      list: {
        items: [],
        count: 0
      }
    },
    comment: {
      current: null,
      list: {
        items: [],
        count: 0,
      }
    },
  },
  getters: {
    pending: (state) => {
      return includes(state.pending, true);
    },
    contracts: (state) => {
      return state.contract.list;
    },
    contract: (state) => {
      return state.contract.current;
    },
    employee: (state) => {
      return state.employee.current;
    },
    employees: (state) => {
      return state.employee.list;
    },
    comment: (state) => {
      return state.comment.current;
    },
    questionsByCode: (state) => {
      return keyBy(employeeData(get(state, 'employee.current.values')), 'code');
    },
    answerByCurrentQuestions: (state) => {
      return mapValues(keyBy(employeeData(get(state, 'employee.current.values')), 'id'), ({ code }) => code);
    },
    comments: (state) => {
      return groupBy(state.comment.list.items, 'question');
    },
  },
  mutations: {
    [START]: (state, key) => {
      state.pending[key] = true;
    },
    [END]: (state, key) => {
      state.pending[key] = false;
    },
    [SET_CONTRACT_LIST]: (state, list) => {
      state.contract.list = list;
    },
    [SET_CONTRACT_CURRENT]: (state, current) => {
      state.contract.current = current;
    },
    [SET_EMPLOYEE_LIST]: (state, list) => {
      state.employee.list = list;
    },
    [SET_EMPLOYEE_CURRENT]: (state, current) => {
      state.employee.current = current;
    },
    [SET_COMMENT_LIST]: (state, list) => {
      state.comment.list = list;
    },
    [SET_COMMENT_CURRENT]: (state, current) => {
      state.comment.current = current;
    },
  },
  actions: {
    fetchContractList: ({ commit }, { page, size, filter }) => {
      commit(START, 'fetchContractList');
      return api.contractList({ page, size, filter }).then((list) => {
        commit(SET_CONTRACT_LIST, list);
        commit(END, 'fetchContractList');
        return list;
      }).catch(() => {
        commit(END, 'fetchContractList');
      });

    },
    fetchContractDetail: ({ commit }, { id }) => {
      commit(START, 'fetchContractDetail');
      return api.contractDetail({ id }).then(detail => {
        commit(SET_CONTRACT_CURRENT, detail);
        commit(END, 'fetchContractDetail');
        return detail;
      }).catch(() => {
        commit(END, 'fetchContractDetail');
      });
    },
    createContract: ({ commit }, { inn, date, name, number }) => {
      commit(START, 'createContract');
      return api.createContract({ inn, date, name, number }).then(result => {
        commit(END, 'createContract');
        return result;
      }).catch(() => {
        commit(END, 'createContract');
      });
    },
    deleteContract: ({ commit }, { id }) => {
      commit(START, 'deleteContract');
      return api.deleteContract({ id }).then(success => {
        commit(END, 'deleteContract');
        return success;
      }).catch(() => {
        return false;
      });
    },
    setWorkTimeToContract: ({ commit }, { id, values }) => {
      commit(START, 'setWorkTimeToContract');
      return api.setWorkTimeToContract({ id, values }).then(result => {
        commit(END, 'setWorkTimeToContract');
        return result;
      }).catch(() => {
        commit(END, 'setWorkTimeToContract');
        return false;
      });
    },

    fetchEmployeeList: ({ commit }, { page, size, filter }) => {
      commit(START, 'fetchEmployeeList');
      return api.employeeList({ page, size, filter }).then(list => {
        commit(SET_EMPLOYEE_LIST, list);
        commit(END, 'fetchEmployeeList');
        return list;
      }).catch(() => {
        commit(END, 'fetchEmployeeList');
      });
    },
    fetchEmployeeDetail: ({ commit }, { id }) => {
      commit(START, 'fetchEmployeeDetail');
      return api.employeeDetail({ id }).then(detail => {
        commit(SET_EMPLOYEE_CURRENT, detail);
        commit(END, 'fetchEmployeeDetail');
        return detail;
      }).catch(() => {
        commit(END, 'fetchEmployeeDetail');
      });

    },
    updateEmployee: ({ commit }, { id, values }) => {
      commit(START, 'updateEmployee');
      return api.updateEmployee({ id, values }).then(result => {
        commit(END, 'updateEmployee');
        return result;
      }).catch(() => {
        commit(END, 'updateEmployee');
      });
    },
    createEmployee: ({ commit }, { id }) => {
      commit(START, 'createEmployee');
      return api.createEmployee({ id }).then((result) => {
        commit(END, 'createEmployee');
        return result.employeeId;
      }).catch(() => {
        commit(END, 'createEmployee');
      });
    },
    removeEmployeeFromContract: ({ commit }, { employees, contract }) => {
      commit(START, 'removeEmployeeFromContract');
      return api.removeEmployeeFromContract({ employees, contract }).then(result => {
        commit(END, 'removeEmployeeFromContract');
        return result;
      }).catch(() => {
        commit(END, 'removeEmployeeFromContract');
      });
    },
    addEmployeeToContract: ({ commit }, { employees, contract }) => {
      commit(START, 'addEmployeeToContract');
      return api.addEmployeeToContract({ employees, contract }).then(result => {
        commit(END, 'addEmployeeToContract');
        return result;
      }).catch(() => {
        commit(END, 'addEmployeeToContract');
      });
    },
    fetchCommentList: ({ commit }, { id }) => {
      commit(START, 'fetchCommentList');
      return api.commentList({ id }).then(list => {
        commit(SET_COMMENT_LIST, list);
        commit(END, 'fetchCommentList');
        return list;
      }).catch(() => {
        commit(END, 'fetchCommentList');
      });
    },
    createComment: ({ commit }, { id, employee, message }) => {
      commit(START, 'createComment');
      return api.createComment({ id, employee, message }).then(result => {
        commit(END, 'createComment');
        return result;
      }).catch(() => {
        commit(END, 'createComment');
      });
    },
    viewedComments: ({ commit }, { items }) => {
      commit(START, 'viewedComments');
      return api.viewedComments({ items }).then(() => {
        commit(END, 'viewedComments');
        return true;
      }).catch(() => {
        commit(END, 'viewedComments');
        return false;
      });
    },
  }
};
