import {get, map, toString, mapValues, toNumber, groupBy, flatten, values, split, head} from 'lodash-es';
import {shortName} from '@/store/inspect/selectors';

export const section = (item, code) => {
  return {
    code,
    mark: toNumber(get(item, 'profileMark', 0)),
    label: toString(get(item, 'label')),
    hint: toString(get(item, 'hint')),
    sections: mapValues(get(item, 'sections'), section),
    questions: mapValues(get(item, 'questions'), (item, code) => ({
      code,
      type: toString(get(item, 'type', 'TEXT')),
      hint: toString(get(item, 'hint', '')),
      label: toString(get(item, 'label')),
      multiple: get(item, 'isMultiple', false),
      changed: !!get(item, 'isChanged', false),
      readonly: !!get(item, 'isReadonly', false),
      options: map(get(item, 'options'), (item) => ({
        text: toString(get(item, 'text')),
        value: toString(get(item, 'value')),
        readonly: false,
      })),
      risks: get(item, 'riskXmlIds'),
      userTypes: map(split(get(item, 'userTypes'), ','), toString),
      values: groupBy(map(get(item, 'values'), (item) => ({
        id: toString(get(item, 'id')),
        type: toString(get(item, 'type', 'TEXT')),
        date: toString(get(item, 'dateExpire')),
        value: toString(get(item, 'value')),
        required: !!get(item, 'isRequired', false),
        readonly: !!get(item, 'isReadonly', false),
        deprecated: !!get(item, 'isDeprecated', false),
        additional: get(item, 'additional', {}),
      })), 'type'),
      rejections: groupBy(map(get(item, 'rejections'), (item) => ({
        id: toString(get(item, 'id')),
        type: toString(get(item, 'type', 'TEXT')),
        date: toString(get(item, 'dateExpire')),
        value: toString(get(item, 'value')),
        required: !!get(item, 'isRequired', false),
        readonly: !!get(item, 'isReadonly', false),
        deprecated: !!get(item, 'isDeprecated', false),
        additional: get(item, 'additional', {}),
      })), 'type'),
    })),
  }
};

// Обратная функция к section
export const sectionPayload = item => ({
  label: toString(get(item, 'label')),
  sections: mapValues(get(item, 'sections'), sectionPayload),
  questions: mapValues(get(item, 'questions'), (item) => ({
    type: toString(get(item, 'type', 'TEXT')),
    label: toString(get(item, 'label')),
    isMultiple: get(item, 'multiple', false),
    isChanged: !!get(item, 'changed', false),
    isRequired: !!get(item, 'required', false),
    options: map(get(item, 'options'), (item) => ({
      text: toString(get(item, 'text')),
      value: toString(get(item, 'value')),
    })),
    values: map(flatten(values(get(item, 'values'))), (item) => ({
      id: toString(get(item, 'id')),
      type: toString(get(item, 'type', 'TEXT')),
      dateExpire: toString(get(item, 'date')),
      value: toString(get(item, 'value')),
      isRequired: !!get(item, 'required', false),
      isReadonly: !!get(item, 'readonly', false),
      isDeprecated: !!get(item, 'deprecated', false),
      additional: get(item, 'additional'),
    })),
    rejections: map(flatten(values(get(item, 'rejections'))), (item) => ({
      id: toString(get(item, 'id')),
      type: toString(get(item, 'type', 'TEXT')),
      dateExpire: toString(get(item, 'date')),
      value: toString(get(item, 'value')),
      isRequired: !!get(item, 'required', false),
      isReadonly: !!get(item, 'readonly', false),
      isDeprecated: !!get(item, 'deprecated', false),
      additional: get(item, 'additional'),
    })),
  }))
});

export const counterpartyDetail = (result) => {
  return {
    id: toString(get(result, 'id')),
    inn: toString(get(result, 'author.companyInn')),
    name: toString(get(result, 'author.companyName')),
    fio: shortName(get(result, 'author', {})),
    email: toString(get(result, 'author.email')),
    phone: toString(get(result, 'author.phone')),
    authorId: toString(get(result, 'author.id')),
    firstGeneralContact: {
      fio: toString(get(result, 'author.firstGenContact.fio', '')),
      email: toString(get(result, 'author.firstGenContact.email', '')),
      phone: toString(get(result, 'author.firstGenContact.phone', '')),
      position: toString(get(result, 'author.firstGenContact.position', '')),
    },
    address: toString(get(result, 'author.address')),
    company: toString(get(result, 'company.name')),
    archive: get(result, 'isArchive', false),
    status: {
      value: toString(get(result, 'status.xmlId')),
      text: toString(get(result, 'status.name')),
    },
    needFullReview: get(result, 'needFullReview', false),
    dateExpire: toString(get(result, 'dateExpire')),
    mark: {
      full: {
        average: toNumber(get(result, 'marks.full.averageProfileMark', 0)),
        general: toNumber(get(result, 'marks.full.generalQualificationMark', 0)),
        integral: toNumber(get(result, 'marks.full.integralComprehensiveMark', 0)),
      },
      simple: {
        average: toNumber(get(result, 'marks.withoutGeneral.averageProfileMark', 0)),
        general: toNumber(get(result, 'marks.withoutGeneral.generalQualificationMark', 0)),
        integral: toNumber(get(result, 'marks.withoutGeneral.integralComprehensiveMark', 0)),
      },
    },
    items: mapValues(get(result, 'items'), section),
    version: toString(get(result, 'version', '0')),
    versions: map(get(result, 'versions', []), (item) => ({
      text: toString(get(item, 'name')),
      value: toString(get(item, 'id')),
    })),
    canChangeDecision: get(result, 'canChangeDecision', false),
  };
};

export const counterpartyList = (result) => ({
  items: map(get(result, 'items'), (item) => ({
    id: toString(get(item, 'id')),
    inn: toString(get(item, 'author.companyInn')),
    name: toString(get(item, 'author.companyName')),
    status: toString(get(item, 'lastApprovementStatus.name')),
    company: toString(get(item, 'company.name')),
  })),
  count: toNumber(get(result, 'navCount', 0)),
});

export const commentList = (result) => ({
  items: map(result.items, (item) => {
    const name = get(item, 'author.name', '');
    const second = get(item, 'author.secondName', '');
    const last = get(item, 'author.lastName', '');
    let short = last;
    if (name) short += ` ${head(name)}.`;
    if (second) short += `${head(second)}.`;
    return {
      id: toString(get(item, 'id')),
      date: toString(get(item, 'dateCreated')),
      author: short,
      question: {
        id: toString(get(item, 'questionXmlId')),
        name: toString(get(item, 'questionText')),
      },
      message: toString(get(item, 'message')),
      draft: get(item, 'isDraft', false),
      actual: get(item, 'isActual', false),
    };
  }),
  count: result.length,
});

export const registryList = (result) => ({
  items: map(get(result, 'items'), (item) => ({
    id: toString(get(item, 'id')),
    inn: toString(get(item, 'author.companyInn')),
    name: toString(get(item, 'author.companyName')),
    company: toString(get(item, 'company.name')),
    dateSend: toString(get(item, 'dateSend')),
    formId: toString(get(item, 'formId')),
    timelinePki: {
      code: toString(get(item, 'timelinePki.status.xmlId')),
      name: toString(get(item, 'timelinePki.status.name')),
      dateCreate: toString(get(item, 'timelinePki.date')),
      dateExec: toString(get(item, 'timelinePki.dateEnd')),
    },
    timelineSb: {
      code: toString(get(item, 'timelineSb.status.xmlId')),
      name: toString(get(item, 'timelineSb.status.name')),
      dateCreate: toString(get(item, 'timelineSb.date')),
      dateExec: toString(get(item, 'timelineSb.dateEnd')),
    },
    timelineOtipb: {
      code: toString(get(item, 'timelineOtipb.status.xmlId')),
      name: toString(get(item, 'timelineOtipb.status.name')),
      dateCreate: toString(get(item, 'timelineOtipb.date')),
      dateExec: toString(get(item, 'timelineOtipb.dateEnd')),
    },
    timelineBdd: {
      code: toString(get(item, 'timelineBdd.status.xmlId')),
      name: toString(get(item, 'timelineBdd.status.name')),
      dateCreate: toString(get(item, 'timelineBdd.date')),
      dateExec: toString(get(item, 'timelineBdd.dateEnd')),
    },
    status: {
      code: toString(get(item, 'status.xmlId')),
      name: toString(get(item, 'status.name')),
    },
    statusDate: toString(get(item, 'statusDate')),
    statusEndDate: toString(get(item, 'statusEndDate')),
    isInTerritory: get(item, 'isInTerritory'),
    sections: mapValues(get(item, 'risksStatuses'), ({ name, status }) => ({
      name,
      value: {
        code: get(status, 'xmlId'),
        name: get(status, 'name'),
      },
    })),
  })),
  count: toNumber(get(result, 'navCount', 0)),
});

export const companiesList = (result) => map(get(result, 'items'), (item) => ({
  id: item.id,
  text: item.name,
  value: item.code,
}));

export const approvalList = (result) => map(get(result, 'items'), (item, index) => {
  const name = get(item, 'author.name', '');
  const second = get(item, 'author.secondName', '');
  const last = get(item, 'author.lastName', '');
  let short = last;
  if (name) short += ` ${head(name)}.`;
  if (second) short += `${head(second)}.`;
  return {
    id: toString(get(item, 'id')),
    step: index,
    name: item.name,
    status: item.status.name,
    code: item.status.xmlId,
    reviewer: short,
    reviewerId: toString(get(item, 'author.id')),
    date: toString(get(item, 'dateEnd', '')),
    possibleReviewers: map(get(item, 'possibleReviewers'), (item) => ({
      id: toString(get(item, 'id')),
      name: shortName(item),
      email: toString(get(item, 'email')),
    })),
  };
});
export const approvalHistoryList = (result) => map(get(result, 'items'), (item, index) => {
  const name = get(item, 'author.name', '');
  const second = get(item, 'author.secondName', '');
  const last = get(item, 'author.lastName', '');
  let short = last;
  if (name) short += ` ${head(name)}.`;
  if (second) short += `${head(second)}.`;
  return {
    id: index,
    author: short,
    status: item.status.name,
    code: item.status.xmlId,
    publicComment: toString(get(item, 'publicComment', '')),
    internalComment: toString(get(item, 'internalComment', '')),
    date: toString(get(item, 'dateCreate', '')),
    role: toString(get(item, 'reviewerName', ''))
  };
});

export const counterpartyViewedQuestions = (result) => get(result, 'questions', {});

export const risksList = (result) => map(get(result, 'items'), (item) => ({
  id: toString(get(item, 'id')),
  name: toString(get(item, 'name')),
  code: toString(get(item, 'xmlId')),
}));

export const registryCurrent = (result) => map(get(result, 'items'), (item) => ({
  company: toString(get(item, 'company.name')),
  status: {
    code: toString(get(item, 'status.xmlId')),
    name: toString(get(item, 'status.name')),
  },
  date: toString(get(item, 'dateEnd')),
}));
